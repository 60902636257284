import React, { useState, useEffect } from "react";
import {
  Box,
  Link,
  Flex,
  Text,
  useDisclosure,
  Button,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import WCode from "src/assets/images/woman-body.png";
import MCode from "src/assets/images/man-body.png";
import BaseView from "../BaseView";
import { Link as ReactRouterLink, useHistory } from "react-router-dom";
import DiseasesSearchBar from "./components/DiseaseSearchBar";
import { Disease } from "src/types";
import { get } from "src/api";
import DiseaseModal from "./components/DiseaseModal";
import Calibration from "src/views/Affirmation/Calibration";
import CalibrationModal from "src/views/Affirmation/Calibration/CalibrationModal";
import { INTENTION_ROUTE, SUBLIMINAL_ROUTE } from "src/routes";
import { calibrationFunc as calibrationFuncAction } from "src/store/calibration";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import MaleHead from "src/assets/images/man-head-5.png";
import MaleAbs from "src/assets/images/man-abbs-5.png";
import MaleLegs from "src/assets/images/man-limbs-5.png";
import FemaleHead from "src/assets/images/woman-head-5.png";
import FemaleAbs from "src/assets/images/woman-abbs-5.png";
import FemaleLegs from "src/assets/images/woman-limbs-5.png";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";

// mapping for images
const map = {
  male: {
    head: MaleHead,
    abdomen: MaleAbs,
    limps: MaleLegs,
  },
  female: {
    head: FemaleHead,
    abdomen: FemaleAbs,
    limps: FemaleLegs,
  },
};

const Parts = () => {
  //get slug from url
  const getSlug = () => {
    const slug = window.location.pathname.split("/")[4];
    return slug;
  };

  //get image either male or female
  const getImage = () => {
    const slug = getSlug();
    const map = {
      male: MCode,
      female: WCode,
    };
    if (slug === "male" || slug === "female") return map[slug];
  };

  const [DiseaseList, setDiseaseList] = useState<Disease[]>([]);
  const [currentDisease, setCurrentDisease] = useState<Disease>();
  const [searchTerm, setSearchTerm] = useState("");

  //calibration modal
  const [open, setOpen] = useState(false);

  //history
  const history = useHistory();

  const dispatch = useDispatch();
  const calibrationFunc = () => dispatch(calibrationFuncAction());

  const { locale } = useIntl();

  //visibility control for modal
  const disclosureProps = useDisclosure();

  //method to open modal
  const openModal = (disease: Disease) => {
    setCurrentDisease(disease);
    disclosureProps.onOpen();
  };

  useEffect(() => {
    const fetchDiseases = async () => {
      try {
        const response: any = await get(
          `/healing/search?q=${searchTerm}&gender=${getSlug()}`
        );

        if (response.data && Array.isArray(response.data)) {
          setDiseaseList(response.data);
        } else {
          // Handle cases where response.data might not be an array or is empty
          setDiseaseList([]);
          console.warn("No diseases found for the given search term.");
        }
      } catch (error) {
        console.error("Error fetching diseases:", error);
        setDiseaseList([]);
      }
    };

    fetchDiseases();
  }, [searchTerm]);

  //method to go for intention sending
  const goToIntention = () => {
    //User have to be calibrated before redirection
    if (window.localStorage.getItem("_sal-Calibra") !== "done") {
      setOpen(true);
    } else {
      history.push(`${INTENTION_ROUTE}?sym=${currentDisease?.id}`);
    }
  };

  const goToSubliminal = () => {
    if (window.localStorage.getItem("_sal-Calibra") !== "done") {
      setOpen(true);
    } else {
      history.push(`${SUBLIMINAL_ROUTE}?sym=${currentDisease?.id}&idx=2`);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(DiseaseList.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentDiseases = DiseaseList.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <BaseView>
      <DiseasesSearchBar
        value={searchTerm}
        onChange={(event) => {
          setSearchTerm(event.target.value);
        }}
      />
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        mx={{ base: 5, md: 0 }}
        flexDir={{ base: "column", md: "row" }}
      >
        <Box w={{ base: "100%", md: "50%" }}>
          {currentDiseases.map((disease) => (
            <Box
              borderRadius="10px"
              boxShadow="-2px 3px 5px silver"
              transition="250ms ease-out"
              border="2px solid transparent"
              _hover={{ borderColor: "#2895ff" }}
              cursor="pointer"
              p={4}
              my={4}
              bgColor={"#fcfafa"}
              onClick={() => openModal(disease)}
            >
              <Flex alignItems={"center"}>
                <Box flex={1}>
                  <Text fontWeight={"bold"}>{disease.name}</Text>
                  <Text color={"gray"} fontSize={14} noOfLines={1}>
                    {disease.description}
                  </Text>
                </Box>
                <img
                  src={
                    map[disease.category!.split("-")[0]][
                      disease.category!.split("-")[1]
                    ]
                  }
                  alt={disease.category}
                  style={{
                    paddingRight:
                      disease.category?.split("-")[1] !== "head" &&
                      locale === "en"
                        ? "10px"
                        : 0,
                    paddingLeft:
                      disease.category?.split("-")[1] !== "head" &&
                      locale === "ar"
                        ? "10px"
                        : 0,
                  }}
                />
              </Flex>
            </Box>
          ))}
          {/* Pagination Controls */}
          {DiseaseList.length > itemsPerPage && (
            <Flex justify="center" align="center" mt={6} mb={8}>
              <IconButton
                aria-label={"pervious"}
                icon={
                  locale === "en" ? <ChevronLeftIcon /> : <ChevronRightIcon />
                }
                size="sm"
                variant="outline"
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
                mx={2}
                borderColor="#2895ff"
                _hover={{ bg: "blue.50" }}
              />

              <HStack spacing={2}>
                {currentPage > 2 && (
                  <>
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={() => handlePageChange(1)}
                    >
                      1
                    </Button>
                    {currentPage > 3 && <Text color="gray.500">...</Text>}
                  </>
                )}

                {Array.from({ length: totalPages }, (_, i) => i + 1)
                  .filter(
                    (page) =>
                      page === 1 ||
                      page === totalPages ||
                      Math.abs(currentPage - page) <= 1
                  )
                  .map((page) => (
                    <Button
                      key={page}
                      size="sm"
                      onClick={() => handlePageChange(page)}
                      bg={currentPage === page ? "#2895ff" : "white"}
                      color={currentPage === page ? "white" : "gray.700"}
                      borderWidth={1}
                      borderColor={
                        currentPage === page ? "#2895ff" : "gray.200"
                      }
                      _hover={{
                        bg: currentPage === page ? "#2895ff" : "blue.50",
                        borderColor: "#2895ff",
                      }}
                    >
                      {page}
                    </Button>
                  ))}

                {currentPage < totalPages - 1 && (
                  <>
                    {currentPage < totalPages - 2 && (
                      <Text color="gray.500">...</Text>
                    )}
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={() => handlePageChange(totalPages)}
                    >
                      {totalPages}
                    </Button>
                  </>
                )}
              </HStack>

              <IconButton
                aria-label={"next"}
                icon={
                  locale === "en" ? <ChevronRightIcon /> : <ChevronLeftIcon />
                }
                size="sm"
                variant="outline"
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
                mx={2}
                borderColor="#2895ff"
                _hover={{ bg: "blue.50" }}
              />

              <Text ml={4} color="gray.500" fontSize="sm">
                <FormattedMessage
                  id="pagination.info"
                  defaultMessage="Page {current} of {total}"
                  values={{
                    current: currentPage,
                    total: totalPages,
                  }}
                />
              </Text>
            </Flex>
          )}
        </Box>

        <Box position="relative" width={"250px"} margin={"60px 10px"}>
          <img src={getImage()} alt={"body part"} />
          {getSlug() === "male" ? (
            <>
              <Link
                as={ReactRouterLink}
                to={`${getSlug()}/head`}
                width="60px"
                height="90px"
                borderRadius="50%"
                pos="absolute"
                top="0"
                left="50%"
                transform="translate(-50%, 15%)"
              />
              <Link
                as={ReactRouterLink}
                to={`${getSlug()}/abdomen`}
                width="100px"
                height="170px"
                borderRadius="10px"
                pos="absolute"
                top="0"
                left="50%"
                transform="translate(-50%, 75%)"
              />
              <Link
                as={ReactRouterLink}
                to={`${getSlug()}/limps`}
                width="40px"
                height="250px"
                borderRadius="10px"
                pos="absolute"
                top="0"
                left="0"
                transform="translate(70%, 50%) rotate(3deg)"
              />
              <Link
                as={ReactRouterLink}
                to={`${getSlug()}/limps`}
                width="40px"
                height="250px"
                borderRadius="10px"
                pos="absolute"
                top="0"
                right="0"
                transform="translate(-70%, 50%) rotate(-3deg)"
              />
              <Link
                as={ReactRouterLink}
                to={`${getSlug()}/limps`}
                width="100px"
                height="295px"
                borderRadius="10px"
                pos="absolute"
                top="0"
                left="50%"
                transform="translate(-50%, 103%)"
              />
            </>
          ) : (
            <>
              <Link
                as={ReactRouterLink}
                to={`${getSlug()}/head`}
                width="60px"
                height="90px"
                borderRadius="50%"
                pos="absolute"
                top="0"
                left={locale === "en" ? "50%" : "46%"}
                transform="translate(-42%, 40%)"
              />
              <Link
                as={ReactRouterLink}
                to={`${getSlug()}/abdomen`}
                width="80px"
                height="140px"
                borderRadius="10px"
                pos="absolute"
                top="0"
                left={locale === "en" ? "50%" : "46.5%"}
                transform="translate(-45%, 110%)"
              />
              <Link
                as={ReactRouterLink}
                to={`${getSlug()}/limps`}
                width="30px"
                height="245px"
                borderRadius="10px"
                pos="absolute"
                top="0"
                left="0"
                transform={
                  locale === "en"
                    ? "translate(160%, 55%) rotate(4deg)"
                    : "translate(135%, 55%) rotate(-5deg)"
                }
              />
              <Link
                as={ReactRouterLink}
                to={`${getSlug()}/limps`}
                width="30px"
                height="245px"
                borderRadius="10px"
                pos="absolute"
                top="0"
                right="0"
                transform={
                  locale === "en"
                    ? "translate(-130%, 55%) rotate(-4deg)"
                    : "translate(-165%, 55%) rotate(5deg)"
                }
              />
              <Link
                as={ReactRouterLink}
                to={`${getSlug()}/limps`}
                width="100px"
                height="295px"
                borderRadius="10px"
                pos="absolute"
                top="0"
                left={locale === "en" ? "50%" : "46%"}
                transform="translate(-45%, 105%)"
              />
            </>
          )}
        </Box>
      </Flex>
      <DiseaseModal
        isOpen={disclosureProps.isOpen}
        onClose={() => {
          disclosureProps.onClose();
          setCurrentDisease(undefined);
        }}
        disease={currentDisease}
        onSendIntention={goToIntention}
        onSubliminal={goToSubliminal}
      />
      <Calibration />
      <CalibrationModal
        children={
          <Button
            onClick={() => {
              calibrationFunc && calibrationFunc();
              setOpen(false);
            }}
            color={"white"}
            width={localStorage.getItem("locale") === "ar" ? "168px" : "176px"}
            height={"40px"}
            background={"#2895ff"}
            fontFamily={"tajwal"}
            fontSize={"16px"}
            fontWeight={"normal"}
            mt={"20px"}
            rightIcon={
              <AiOutlinePlayCircle
                fontSize={"24px"}
                style={
                  localStorage.getItem("locale") === "ar"
                    ? { marginRight: "1rem" }
                    : { marginLeft: "1px" }
                }
              />
            }
          >
            <FormattedMessage
              id="calibration.start"
              defaultMessage={"بدء المعايرة"}
            />
          </Button>
        }
        open={open}
        modalClose={() => setOpen(false)}
      />
    </BaseView>
  );
};

export default Parts;
