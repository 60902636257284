import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  SimpleGrid,
  Center,
  Icon,
  Circle,
  Button,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { TimeIcon } from "@chakra-ui/icons";
import { LuTimer } from "react-icons/lu";
import { MdOutlineCalendarToday } from "react-icons/md";
import { FiWatch } from "react-icons/fi";
import { useSelector } from "src/store";
import { useHistory } from "react-router-dom";
import { SUBSCRIPTION_ROUTE } from "src/routes";

const SubscriptionDetails: React.FC = () => {
  const { formatMessage } = useIntl();
  const { user } = useSelector((state) => state.auth);
  const initialState =
    new Date(user?.subscription?.endDate || "").getTime() -
    new Date(Date.now()).getTime();
  const [remaining, setRemaining] = useState(initialState);

  const history = useHistory();

  const remainingDays = Math.floor(remaining / (1000 * 60 * 60 * 24));
  const remainingHours = Math.floor(
    (remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const remainingMinutes = Math.floor(
    (remaining % (1000 * 60 * 60)) / (1000 * 60)
  );
  const remainingSeconds = Math.floor((remaining % (1000 * 60)) / 1000);

  const warningColor = "#E53E3E";
  const warningBgColor = "#E53E3E80";
  const primaryColor = "#162d6a";
  const primaryBgColor = "#162d6a80";
  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining((rem) => rem - 1000);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box
      p={6}
      backgroundColor="white"
      w="full"
      borderRadius="lg"
      border="1px solid #e6e6e6"
      sx={{ fontFamily: "Calibri, sans-serif" }}
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.2)"
    >
      <Text
        color={remainingDays <= 10 ? warningColor : primaryColor}
        fontSize={"2xl"}
        fontWeight={"semibold"}
      >
        {formatMessage({ id: "registerData" })}
      </Text>
      <Text fontSize={"md"} color="gray.500" mb={{ base: 4, md: 5 }}>
        {formatMessage({ id: "subscription.remaining" })}
      </Text>

      <SimpleGrid columns={{ base: 2, md: 4 }} spacing={{ base: 4, md: 6 }}>
        <Box
          p={{ base: 3, md: 4 }}
          bg="white"
          borderRadius="md"
          border="1px solid #e6e6e6"
          _hover={{ bgColor: "#F5F5F5" }}
        >
          <Center flexDirection="column" height="100%">
            <Circle
              size={{ base: "28px", md: "32px" }}
              bg={remainingDays <= 10 ? warningBgColor : primaryBgColor}
              mb={2}
            >
              <Icon
                as={MdOutlineCalendarToday}
                w={{ base: 3.5, md: 4 }}
                h={{ base: 3.5, md: 4 }}
                color={"white"}
              />
            </Circle>
            <Text
              fontSize={{ base: "36px", md: "48px" }}
              fontWeight="600"
              color={remainingDays <= 10 ? warningColor : primaryColor}
              lineHeight="1"
            >
              {remainingDays}
            </Text>
            <Text
              fontSize={{ base: "12px", md: "13px" }}
              color="#666666"
              mt={1}
            >
              {formatMessage({ id: "subscription.days" })}
            </Text>
          </Center>
        </Box>

        <Box
          p={{ base: 3, md: 4 }}
          bg="white"
          borderRadius="md"
          border="1px solid #e6e6e6"
          _hover={{ bgColor: "#F5F5F5" }}
        >
          <Center flexDirection="column" height="100%">
            <Circle
              size={{ base: "28px", md: "32px" }}
              bg={remainingDays <= 10 ? warningBgColor : primaryBgColor}
              mb={2}
            >
              <Icon
                as={TimeIcon}
                w={{ base: 3.5, md: 4 }}
                h={{ base: 3.5, md: 4 }}
                color={"white"}
              />
            </Circle>
            <Text
              fontSize={{ base: "36px", md: "48px" }}
              fontWeight="600"
              color={remainingDays <= 10 ? warningColor : primaryColor}
              lineHeight="1"
            >
              {remainingHours}
            </Text>
            <Text
              fontSize={{ base: "12px", md: "13px" }}
              color="#666666"
              mt={1}
            >
              {formatMessage({ id: "subscription.hours" })}
            </Text>
          </Center>
        </Box>

        <Box
          p={{ base: 3, md: 4 }}
          bg="white"
          borderRadius="md"
          border="1px solid #e6e6e6"
          _hover={{ bgColor: "#F5F5F5" }}
        >
          <Center flexDirection="column" height="100%">
            <Circle
              size={{ base: "28px", md: "32px" }}
              bg={remainingDays <= 10 ? warningBgColor : primaryBgColor}
              mb={2}
            >
              <Icon
                as={LuTimer}
                w={{ base: 3.5, md: 4 }}
                h={{ base: 3.5, md: 4 }}
                color={"white"}
              />
            </Circle>
            <Text
              fontSize={{ base: "36px", md: "48px" }}
              fontWeight="600"
              color={remainingDays <= 10 ? warningColor : primaryColor}
              lineHeight="1"
            >
              {remainingMinutes}
            </Text>
            <Text
              fontSize={{ base: "12px", md: "13px" }}
              color="#666666"
              mt={1}
            >
              {formatMessage({ id: "subscription.minutes" })}
            </Text>
          </Center>
        </Box>

        <Box
          p={{ base: 3, md: 4 }}
          bg="white"
          borderRadius="md"
          border="1px solid #e6e6e6"
          _hover={{ bgColor: "#F5F5F5" }}
        >
          <Center flexDirection="column" height="100%">
            <Circle
              size={{ base: "28px", md: "32px" }}
              bg={remainingDays <= 10 ? warningBgColor : primaryBgColor}
              mb={2}
            >
              <Icon
                as={FiWatch}
                w={{ base: 3.5, md: 4 }}
                h={{ base: 3.5, md: 4 }}
                color={"white"}
              />
            </Circle>
            <Text
              fontSize={{ base: "36px", md: "48px" }}
              fontWeight="600"
              color={remainingDays <= 10 ? warningColor : primaryColor}
              lineHeight="1"
            >
              {remainingSeconds}
            </Text>
            <Text
              fontSize={{ base: "12px", md: "13px" }}
              color="#666666"
              mt={1}
            >
              {formatMessage({ id: "subscription.seconds" })}
            </Text>
          </Center>
        </Box>
      </SimpleGrid>
      <Box
        d="flex"
        fontWeight="bold"
        alignItems="center"
        justifyContent="right"
        marginTop="25px"
      >
        {remainingDays <= 10 && (
          <Button
            w={"full"}
            onClick={() => {
              history.push(SUBSCRIPTION_ROUTE, { renew: true });
            }}
            bgColor={warningColor}
            color="white"
            variant="solid"
            size="md"
          >
            {formatMessage({ id: "subscription.renewNow" })}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default SubscriptionDetails;
