import React from "react";
import {
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { useIntl } from "react-intl";

interface DiseasesSearchBarProps {
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DiseasesSearchBar: React.FC<DiseasesSearchBarProps> = ({
  value,
  onChange,
}) => {
  const theme = useTheme();

  const { formatMessage } = useIntl();

  return (
    <VStack mt={10} width="100%" maxWidth="600px" spacing={2} align="stretch">
      <Text fontSize="xl" color="#1f3f93">
        {formatMessage({ id: "find_diseases" })}
      </Text>
      <InputGroup size="lg">
        <InputLeftElement
          pointerEvents="none"
          children={<SearchIcon color="blue.500" />}
        />
        <Input
          type="text"
          value={value}
          placeholder={formatMessage({ id: "find_diseases.hint" })}
          bg="white"
          border="2px solid"
          borderColor="gray.200"
          borderRadius="full"
          boxShadow="sm"
          _hover={{
            borderColor: "blue.300",
            boxShadow: "md",
          }}
          _focus={{
            borderColor: "transparent",
            boxShadow: `0 0 0 2px ${theme.colors.purple[500]}`,
          }}
          _placeholder={{ color: "gray.400" }}
          fontSize="md"
          transition="all 0.3s"
          onChange={onChange}
        />
      </InputGroup>
    </VStack>
  );
};

export default DiseasesSearchBar;
