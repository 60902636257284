import React from "react";
import { Text, Button, Flex } from "@chakra-ui/react";
import { useIntl } from "react-intl";

type CookieCheckerProps = {
  accept: () => void;
  analyticsOnly?: boolean;
};

const CookieChecker: React.FC<CookieCheckerProps> = ({
  accept,
  analyticsOnly,
}) => {
  const { formatMessage } = useIntl();

  //message to display
  const message = analyticsOnly
    ? formatMessage({ id: "cookie.analytics.message" })
    : navigator.cookieEnabled
    ? formatMessage({ id: "cookie.enabled.message" })
    : formatMessage({ id: "cookie.disabled.message" });

  //handle accepting cookies, ca stands for cookies accepted
  const acceptAllCookies = () => {
    window.localStorage.setItem("__sal_analytics_consent", "1");
    if (!analyticsOnly) {
      window.localStorage.setItem("__sal_ca", "1");
    }
    accept();
  };

  const acceptEssentialOnly = () => {
    if (!analyticsOnly) {
      window.localStorage.setItem("__sal_ca", "1");
    }
    window.localStorage.setItem("__sal_analytics_consent", "0");
    accept();
  };

  return (
    <Flex
      flexDirection={"column"}
      gap={4}
      position="fixed"
      bottom={5}
      left="50%"
      transform="translateX(-50%)"
      maxWidth="600px"
      minWidth="300px"
      backgroundColor="white"
      borderRadius={"5px"}
      boxShadow="0 2px 8px rgba(0, 0, 0, 0.1)"
      padding={5}
      zIndex={9999}
    >
      <Text fontSize="sm" color="#616161">
        {message}
      </Text>
      <Flex gap={4} flexDirection={{ base: "column", md: "row" }}>
        <Button
          flex={1}
          onClick={acceptAllCookies}
          backgroundColor="#1f3f93"
          color="white"
          borderRadius="5px"
          _hover={{ backgroundColor: "#6279b3" }}
          padding={4}
          width={{ base: "100%", md: "auto" }}
        >
          {formatMessage({
            id: analyticsOnly ? "cookie.accept.analytics" : "cookie.accept.all",
          })}
        </Button>
        <Button
          flex={1}
          onClick={acceptEssentialOnly}
          backgroundColor="white"
          color="black"
          variant="outline"
          borderRadius="5px"
          border="1px solid #dcdcdc"
          _hover={{ backgroundColor: "#f9f9f9" }}
          padding={4}
          width={{ base: "100%", md: "auto" }}
        >
          {formatMessage({
            id: analyticsOnly
              ? "cookie.reject.analytics"
              : "cookie.accept.essential",
          })}
        </Button>
      </Flex>
    </Flex>
  );
};

export default CookieChecker;
