import React from "react";
import {
  Box,
  Modal,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  Text,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { FaCopy, FaUpload } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import { Disease } from "src/types";
import DOMPurify from "dompurify";
import "../../../../richTextStyles.css";

interface DiseaseModalProps {
  isOpen: boolean;
  onClose: () => void;
  disease?: Disease;
  onSendIntention: () => void;
  onSubliminal: () => void;
}

const DiseaseModal: React.FC<DiseaseModalProps> = ({
  isOpen,
  onClose,
  disease,
  onSendIntention,
  onSubliminal,
}) => {
  //Copy Toast
  const intel = useIntl();
  const toast = useToast();
  const fireToast = () => {
    toast({
      position: "top",
      render: () => (
        <Box
          color="white"
          width={"200px"}
          py={"5px"}
          textAlign={"center"}
          bg="brand.800"
        >
          {intel.formatMessage({ id: "values.copy" })}
        </Box>
      ),
    });
  };

  if (!disease) return null;

  //method for copying to clipboard
  const copyToClipboard = (value: string) => {
    if (value) {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(value);
        return fireToast();
      } else {
        let copyText: HTMLInputElement | null =
          document.querySelector("#printTextD");
        copyText!.select();
        document.execCommand("copy");
        return fireToast();
      }
    }
  };

  function createMarkup(text) {
    // Sanitize the resulting HTML
    let sanitized = DOMPurify.sanitize(text);

    // // Remove any other color styles (except those we just added to links)
    sanitized = sanitized.replace(
      /style="([^"]*?)color:[^;"]*;?([^"]*?)"/g,
      'style="$1$2"'
    );

    // First, set all text to black by adding a wrapper

    // Handle links with specific styling
    sanitized = sanitized
      .replace(
        /<a\s+(?![^>]*style=["'])/g, // Match <a> tags without a style attribute
        '<a style="text-decoration: underline; color: #0000FF;" '
      )
      .replace(
        /<a\s+[^>]*style=["'][^"']*["']/g, // Match <a> tags with existing style attributes
        (match) =>
          match.replace(
            /style=["'][^"']*["']/,
            'style="text-decoration: underline; color: #0000FF;"'
          )
      );

    // Ensure links open in a new tab
    sanitized = sanitized.replace(/href/g, `target="_blank" href`) || " ";

    return { __html: sanitized };
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        paddingBlock="10px"
        // paddingInline="25px"
        width={{ base: "90%", sm: "80%", md: "600px" }}
        background="white"
        // marginTop={{ base: "50px", md: "200px" }}
        transform="translate(-50%, -50%)"
        minWidth={{ md: "600px" }}
        borderRadius="20px"
        maxHeight="80vh"
      >
        <ModalHeader padding={"0"} />
        <ModalCloseButton />
        <ModalBody
          minHeight="250px"
          display="flex"
          flexDir="column"
          justifyContent="space-between"
          alignItems="flex-start"
          overflowY="auto"
        >
          <Box>
            <Text fontSize={"28px"} color="#1f3f93" fontWeight="bold">
              {disease.name}
            </Text>
            <Text marginTop="10px" fontSize="14px">
              {disease.description}
            </Text>
          </Box>
          <Flex flexDir="column" padding="30px 0" w={"100%"}>
            <Box>
              <Text
                fontSize={"18px"}
                color="#1f3f93"
                fontWeight="bold"
                textTransform="capitalize"
                marginBottom="10px"
              >
                <FormattedMessage id="affSentence" />
              </Text>
              <Box padding="0 10px">
                {disease.affirmations?.map(
                  (affirmation: string, index: number) => (
                    <Text key={index} padding="5px 0">
                      {affirmation}
                    </Text>
                  )
                )}
              </Box>
            </Box>

            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              mt={10}
            >
              {/* Copy Button */}
              <Box
                onClick={() =>
                  copyToClipboard(disease.affirmations?.join("\n") || "")
                }
              >
                <Box
                  padding="20px"
                  borderRadius="5px"
                  background="#f2eded"
                  cursor="pointer"
                  transition="250ms ease-out"
                  _hover={{ background: "#e0dcdc" }}
                  aria-label="Copy Affirmations"
                >
                  <FaCopy size={24} color="#2895ff" />
                </Box>
                <Text textAlign="center" cursor="pointer">
                  <FormattedMessage id="copy" />
                </Text>
              </Box>
              {/* Send Intention Button */}
              <Box onClick={onSendIntention}>
                <Box
                  padding="20px"
                  borderRadius="5px"
                  background="#f2eded"
                  cursor="pointer"
                  transition="250ms ease-out"
                  _hover={{ background: "#e0dcdc" }}
                  margin={"0 30px"}
                  aria-label="Send Intention"
                >
                  <FaUpload size={24} color="#2895ff" />
                </Box>
                <Text textAlign="center" cursor="pointer">
                  <FormattedMessage id="sendIntention" />
                </Text>
              </Box>
              {/* Subliminal Button */}
              <Box
                onClick={onSubliminal}
                style={{ position: "relative", left: "-30px" }}
              >
                <Box
                  padding="20px"
                  borderRadius="5px"
                  background="#f2eded"
                  cursor="pointer"
                  transition="250ms ease-out"
                  _hover={{ background: "#e0dcdc" }}
                  margin={"0 30px"}
                  aria-label="Subliminal"
                >
                  <FaUpload size={24} color="#2895ff" />
                </Box>
                <Text textAlign="center" cursor="pointer">
                  <FormattedMessage id="Subliminal" />
                </Text>
              </Box>
            </Box>
            {disease.recommended_supplements &&
              disease.recommended_supplements.length > 0 && (
                <Box mt={10}>
                  <Text
                    fontSize={"18px"}
                    color="#1f3f93"
                    fontWeight="bold"
                    textTransform="capitalize"
                    marginBottom="10px"
                  >
                    <FormattedMessage id="supps" />
                  </Text>
                  <Box
                    padding="0 10px"
                    // color="gray.400"
                    // sx={{
                    //   // Ensure that all inner text inherits the color
                    //   "& *": { color: "gray.400" },
                    // }}
                  >
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        disease.recommended_supplements
                      )}
                    />
                  </Box>
                </Box>
              )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DiseaseModal;
