import React, { useState, useEffect, useCallback } from "react";
import xxMaleHead from "src/assets/images/man-head.png";
import xxMaleAbs from "src/assets/images/man-abbs.png";
import xxMaleLegs from "src/assets/images/man-limbs.png";
import xxFemaleHead from "src/assets/images/woman-head.png";
import xxFemaleAbs from "src/assets/images/woman-abbs.png";
import xxFemaleLegs from "src/assets/images/woman-limbs.png";
import {
  Box,
  Flex,
  Text,
  HStack,
  Button,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import BaseView from "../BaseView";
import { Link, useLocation, useHistory } from "react-router-dom";
import { get } from "src/api/index";
import useLocale from "src/providers/useLocale";
import DiseasesSearchBar from "./components/DiseaseSearchBar";
import { Disease } from "src/types";

import xMaleHead from "src/assets/images/man-head-5.png";
import xMaleAbs from "src/assets/images/man-abbs-5.png";
import xMaleLegs from "src/assets/images/man-limbs-5.png";
import xFemaleHead from "src/assets/images/woman-head-5.png";
import xFemaleAbs from "src/assets/images/woman-abbs-5.png";
import xFemaleLegs from "src/assets/images/woman-limbs-5.png";
import DiseaseModal from "./components/DiseaseModal";
import Calibration from "src/views/Affirmation/Calibration";
import CalibrationModal from "src/views/Affirmation/Calibration/CalibrationModal";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { INTENTION_ROUTE, SUBLIMINAL_ROUTE } from "src/routes";
import { calibrationFunc as calibrationFuncAction } from "src/store/calibration";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";

const map = {
  male: {
    head: { x: xMaleHead, xx: xxMaleHead },
    abdomen: { x: xMaleAbs, xx: xxMaleAbs },
    limps: { x: xMaleLegs, xx: xxMaleLegs },
  },
  female: {
    head: { x: xFemaleHead, xx: xxFemaleHead },
    abdomen: { x: xFemaleAbs, xx: xxFemaleAbs },
    limps: { x: xFemaleLegs, xx: xxFemaleLegs },
  },
};
const Inner = () => {
  //function to obtain the gender and body section
  const getSlugs = useCallback(() => {
    const slugs = window.location.pathname.split("/");
    const gender = slugs[4] as unknown as keyof typeof map;
    const section = slugs[5] as unknown as keyof typeof map.male;
    return {
      gender,
      section,
    };
  }, []);

  const locale = useLocale()[0];

  //count of organs for current section
  const [organsCount, setOrgansCount] = useState<any>({});

  useEffect(() => {
    //function to fetch organs count
    const countOrgans = async () => {
      const { gender, section } = getSlugs();
      const response = await get(`/healing/${gender}/${section}`);
      if (response) setOrgansCount(response);
    };
    countOrgans();
  }, [getSlugs, locale]);

  //relative url
  const { pathname } = useLocation();

  //function to return the map for section parts
  const getImageMap = () => {
    if (!organsCount) return;
    const { gender, section } = getSlugs();

    //Depending on the section we return the map for it's corresponding parts
    switch (section) {
      case "head":
        return (
          <>
            <Box
              as={Link}
              to={!organsCount.forehead ? "#" : `${pathname}/forehead`}
              position="absolute"
              width={gender === "male" ? "60%" : "60%"}
              height={"70px"}
              top="0"
              left={locale === "en" ? "50%" : gender === "male" ? "46%" : "55%"}
              transform={
                gender === "male"
                  ? "translate(-46%, 70px)"
                  : "translate(-55%, 85px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.eyes ? "#" : `${pathname}/eyes`}
              position="absolute"
              width={gender === "male" ? "37%" : "37%"}
              height={gender === "male" ? "47px" : "53px"}
              top="0"
              left={
                locale === "en" ? "50%" : gender === "male" ? "46.5%" : "55%"
              }
              transform={
                gender === "male"
                  ? "translate(-46%, 145px)"
                  : "translate(-56%, 158px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.nose ? "#" : `${pathname}/nose`}
              position="absolute"
              width={gender === "male" ? "15%" : "12%"}
              height={gender === "male" ? "42px" : "37px"}
              top="0"
              left={locale === "en" ? "50%" : gender === "male" ? "46%" : "53%"}
              transform={
                gender === "male"
                  ? "translate(-40%, 195px)"
                  : "translate(-60%, 215px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.mouth ? "#" : `${pathname}/mouth`}
              position="absolute"
              width={gender === "male" ? "25%" : "20%"}
              height={"35px"}
              top="0"
              left={locale === "en" ? "50%" : gender === "male" ? "47%" : "54%"}
              transform={
                gender === "male"
                  ? "translate(-45%, 240px)"
                  : "translate(-60%, 255px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.cheeks ? "#" : `${pathname}/cheeks`}
              position="absolute"
              width={gender === "male" ? "9.5%" : "10%"}
              height={gender === "male" ? "42px" : "37px"}
              top="0"
              left="0"
              transform={
                gender === "male"
                  ? locale === "en"
                    ? "translate(117px , 195px)"
                    : "translate(105px , 195px)"
                  : locale === "en"
                  ? "translate(195px , 215px)"
                  : "translate(205px , 215px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.cheeks ? "#" : `${pathname}/cheeks`}
              position="absolute"
              width={gender === "male" ? "9.5%" : "11%"}
              height={gender === "male" ? "42px" : "37px"}
              top="0"
              right="0"
              transform={
                gender === "male"
                  ? locale === "en"
                    ? "translate(-105px, 195px)"
                    : "translate(-122px, 195px)"
                  : locale === "en"
                  ? "translate(-205px, 215px)"
                  : "translate(-191px, 215px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.ears ? "#" : `${pathname}/ears`}
              position="absolute"
              width={gender === "male" ? "7%" : "7%"}
              height={gender === "male" ? "70px" : "65px"}
              top="0"
              left="0"
              transform={
                gender === "male"
                  ? locale === "en"
                    ? "translate(88px, 155px)"
                    : "translate(75px, 155px)"
                  : locale === "en"
                  ? "translate(75px, 170px)"
                  : "translate(90px, 170px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.ears ? "#" : `${pathname}/ears`}
              position="absolute"
              width={gender === "male" ? "7%" : "7%"}
              height={gender === "male" ? "70px" : "65px"}
              top="0"
              right="0"
              transform={
                gender === "male"
                  ? locale === "en"
                    ? "translate(-75px, 155px)"
                    : "translate(-90px, 155px)"
                  : locale === "en"
                  ? "translate(-90px, 170px)"
                  : "translate(-75px, 170px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.neck ? "#" : `${pathname}/neck`}
              position="absolute"
              width={gender === "male" ? "70%" : "55%"}
              height={gender === "male" ? "45px" : "40px"}
              top="0"
              left={locale === "en" ? "50%" : gender === "male" ? "46%" : "60%"}
              transform={
                gender === "male"
                  ? "translate(-45%, 300px)"
                  : "translate(-60%, 315px)"
              }
            />
          </>
        );
      case "abdomen":
        return (
          <>
            <Box
              as={Link}
              to={!organsCount.chest ? "#" : `${pathname}/chest`}
              position="absolute"
              width="45%"
              height={gender === "male" ? "95px" : "100px"}
              top="0"
              left="50%"
              transform={
                gender === "male"
                  ? "translate(-50%, 90px)"
                  : "translate(-50%, 120px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.belly ? "#" : `${pathname}/belly`}
              position="absolute"
              width="45%"
              height={"85px"}
              top="0"
              left="50%"
              transform={
                gender === "male"
                  ? "translate(-50%, 190px)"
                  : "translate(-50%, 225px)"
              }
            />
          </>
        );
      case "limps":
        return (
          <>
            <Box
              as={Link}
              to={!organsCount.arms ? "#" : `${pathname}/arms`}
              position="absolute"
              width={gender === "male" ? "17%" : "15%"}
              height={"170px"}
              top="0"
              left="0"
              transform={
                gender === "male"
                  ? locale === "en"
                    ? "translate(43px, 105px) rotate(8deg)"
                    : "translate(33px, 105px) rotate(-8deg)"
                  : locale === "en"
                  ? "translate(175px, 140px) rotate(-10deg)"
                  : "translate(163px, 140px) rotate(10deg)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.arms ? "#" : `${pathname}/arms`}
              position="absolute"
              width={gender === "male" ? "17%" : "15%"}
              height={"170px"}
              top="0"
              right="0"
              transform={
                gender === "male"
                  ? locale === "en"
                    ? "translate(-33px, 105px) rotate(-8deg)"
                    : "translate(-43px, 105px) rotate(8deg)"
                  : locale === "en"
                  ? "translate(-162px, 140px) rotate(10deg)"
                  : "translate(-175px, 140px) rotate(-10deg)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.hands ? "#" : `${pathname}/hands`}
              position="absolute"
              width={gender === "male" ? "15%" : "12%"}
              height={"60px"}
              top="0"
              left="0"
              transform={
                gender === "male"
                  ? `translate(${locale === "en" ? "35px" : "27px"}, 280px)`
                  : `translate(${locale === "en" ? "190px" : "179px"}, 318px)`
              }
            />
            <Box
              as={Link}
              to={!organsCount.hands ? "#" : `${pathname}/hands`}
              position="absolute"
              width={gender === "male" ? "17%" : "12%"}
              height={"60px"}
              top="0"
              right="0"
              transform={
                gender === "male"
                  ? `translate(${locale === "en" ? "-22px" : "-33px"}, 280px)`
                  : `translate(${locale === "en" ? "-178px" : "-190px"}, 318px)`
              }
            />
            <Box
              as={Link}
              to={!organsCount.legs ? "#" : `${pathname}/upperLegs`}
              position="absolute"
              width={gender === "male" ? "40%" : "45%"}
              height={gender === "male" ? "160px" : "165px"}
              top="0"
              left={locale === "en" ? "50%" : "46%"}
              transform={
                gender === "male"
                  ? "translate(-45%, 250px)"
                  : "translate(-45%, 290px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.feet ? "#" : `${pathname}/lowerLegs`}
              position="absolute"
              width={gender === "male" ? "40%" : "45%"}
              height={gender === "male" ? "142px" : "147px"}
              top="0"
              left={locale === "en" ? "50%" : "46%"}
              transform={
                gender === "male"
                  ? "translate(-45%, 413px)"
                  : "translate(-45%, 458px)"
              }
            />
          </>
        );
    }
  };

  const [DiseaseList, setDiseaseList] = useState<Disease[]>([]);
  const [currentDisease, setCurrentDisease] = useState<Disease>();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchDiseases = async () => {
      try {
        const response: any = await get(
          `/healing/search?q=${searchTerm}&gender=${getSlugs().gender}`
        );

        if (response.data && Array.isArray(response.data)) {
          setDiseaseList(response.data);
        } else {
          // Handle cases where response.data might not be an array or is empty
          setDiseaseList([]);
          console.warn("No diseases found for the given search term.");
        }
      } catch (error) {
        console.error("Error fetching diseases:", error);
        setDiseaseList([]);
      }
    };

    fetchDiseases();
  }, [getSlugs, searchTerm]);

  //calibration modal
  const [open, setOpen] = useState(false);

  //visibility control for modal
  const disclosureProps = useDisclosure();

  //history
  const history = useHistory();

  const dispatch = useDispatch();
  const calibrationFunc = () => dispatch(calibrationFuncAction());

  //method to open modal
  const openModal = (disease: Disease) => {
    setCurrentDisease(disease);
    disclosureProps.onOpen();
  };

  //method to go for intention sending
  const goToIntention = () => {
    //User have to be calibrated before redirection
    if (window.localStorage.getItem("_sal-Calibra") !== "done") {
      setOpen(true);
    } else {
      history.push(`${INTENTION_ROUTE}?sym=${currentDisease?.id}`);
    }
  };

  const goToSubliminal = () => {
    if (window.localStorage.getItem("_sal-Calibra") !== "done") {
      setOpen(true);
    } else {
      history.push(`${SUBLIMINAL_ROUTE}?sym=${currentDisease?.id}&idx=2`);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(DiseaseList.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentDiseases = DiseaseList.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <BaseView>
      <DiseasesSearchBar
        value={searchTerm}
        onChange={(event) => {
          setSearchTerm(event.target.value);
          setCurrentPage(1); // Reset to first page on new search
        }}
      />
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        mx={{ base: 5, md: 0 }}
        flexDir={{ base: "column", md: "row" }}
      >
        <Box w={{ base: "100%", md: "50%" }}>
          {currentDiseases.map((disease) => (
            <Box
              borderRadius="10px"
              boxShadow="-2px 3px 5px silver"
              transition="250ms ease-out"
              border="2px solid transparent"
              _hover={{ borderColor: "#2895ff" }}
              cursor="pointer"
              p={4}
              my={4}
              bgColor={"#fcfafa"}
              onClick={() => openModal(disease)}
            >
              <Flex alignItems={"center"}>
                <Box flex={1}>
                  <Text fontWeight={"bold"}>{disease.name}</Text>
                  <Text color={"gray"} fontSize={14} noOfLines={1}>
                    {disease.description}
                  </Text>
                </Box>
                <img
                  src={
                    map[disease.category!.split("-")[0]][
                      disease.category!.split("-")[1]
                    ].x
                  }
                  alt={disease.category}
                  style={{
                    paddingRight:
                      disease.category?.split("-")[1] !== "head" &&
                      locale === "en"
                        ? "10px"
                        : 0,
                    paddingLeft:
                      disease.category?.split("-")[1] !== "head" &&
                      locale === "ar"
                        ? "10px"
                        : 0,
                  }}
                />
              </Flex>
            </Box>
          ))}
          {/* Pagination Controls */}
          {DiseaseList.length > itemsPerPage && (
            <Flex justify="center" align="center" mt={6} mb={8}>
              <IconButton
                aria-label={"pervious"}
                icon={
                  locale === "en" ? <ChevronLeftIcon /> : <ChevronRightIcon />
                }
                size="sm"
                variant="outline"
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
                mx={2}
                borderColor="#2895ff"
                _hover={{ bg: "blue.50" }}
              />

              <HStack spacing={2}>
                {currentPage > 2 && (
                  <>
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={() => handlePageChange(1)}
                    >
                      1
                    </Button>
                    {currentPage > 3 && <Text color="gray.500">...</Text>}
                  </>
                )}

                {Array.from({ length: totalPages }, (_, i) => i + 1)
                  .filter(
                    (page) =>
                      page === 1 ||
                      page === totalPages ||
                      Math.abs(currentPage - page) <= 1
                  )
                  .map((page) => (
                    <Button
                      key={page}
                      size="sm"
                      onClick={() => handlePageChange(page)}
                      bg={currentPage === page ? "#2895ff" : "white"}
                      color={currentPage === page ? "white" : "gray.700"}
                      borderWidth={1}
                      borderColor={
                        currentPage === page ? "#2895ff" : "gray.200"
                      }
                      _hover={{
                        bg: currentPage === page ? "#2895ff" : "blue.50",
                        borderColor: "#2895ff",
                      }}
                    >
                      {page}
                    </Button>
                  ))}

                {currentPage < totalPages - 1 && (
                  <>
                    {currentPage < totalPages - 2 && (
                      <Text color="gray.500">...</Text>
                    )}
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={() => handlePageChange(totalPages)}
                    >
                      {totalPages}
                    </Button>
                  </>
                )}
              </HStack>

              <IconButton
                aria-label={"next"}
                icon={
                  locale === "en" ? <ChevronRightIcon /> : <ChevronLeftIcon />
                }
                size="sm"
                variant="outline"
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
                mx={2}
                borderColor="#2895ff"
                _hover={{ bg: "blue.50" }}
              />

              <Text ml={4} color="gray.500" fontSize="sm">
                <FormattedMessage
                  id="pagination.info"
                  defaultMessage="Page {current} of {total}"
                  values={{
                    current: currentPage,
                    total: totalPages,
                  }}
                />
              </Text>
            </Flex>
          )}
        </Box>
        <Box
          position="relative"
          width={getSlugs().section === "head" ? "350px" : "250px"}
          margin={"60px 10px"}
        >
          <img
            src={map[getSlugs().gender][getSlugs().section].xx}
            alt={"inner body part"}
          />
          {getImageMap()}
        </Box>
      </Flex>
      <DiseaseModal
        isOpen={disclosureProps.isOpen}
        onClose={() => {
          disclosureProps.onClose();
          setCurrentDisease(undefined);
        }}
        disease={currentDisease}
        onSendIntention={goToIntention}
        onSubliminal={goToSubliminal}
      />
      <Calibration />
      <CalibrationModal
        children={
          <Button
            onClick={() => {
              calibrationFunc && calibrationFunc();
              setOpen(false);
            }}
            color={"white"}
            width={localStorage.getItem("locale") === "ar" ? "168px" : "176px"}
            height={"40px"}
            background={"#2895ff"}
            fontFamily={"tajwal"}
            fontSize={"16px"}
            fontWeight={"normal"}
            mt={"20px"}
            rightIcon={
              <AiOutlinePlayCircle
                fontSize={"24px"}
                style={
                  localStorage.getItem("locale") === "ar"
                    ? { marginRight: "1rem" }
                    : { marginLeft: "1px" }
                }
              />
            }
          >
            <FormattedMessage
              id="calibration.start"
              defaultMessage={"بدء المعايرة"}
            />
          </Button>
        }
        open={open}
        modalClose={() => setOpen(false)}
      />
    </BaseView>
  );
};

export default Inner;
