import React, { useEffect, useMemo, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactPlayer from "react-player";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import classes from "./Calibration.module.css";
import debounce from "lodash.debounce";
import iOS from "src/helpers/iOSDetector";
import { useDispatch, useSelector } from "src/store";
import { onEnd as onEndAction, load } from "src/store/calibration";
import useIsDev from "src/helpers/useIsDev";
//Please add the updated Url of the video source later, once it's seeded at the backend

const getReadableTime = (d: number) => {
  const minutes = Math.floor(d / 60);
  const seconds = Math.floor(d % 60);
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};
const times = ["0:00", "0:01", "0:02"];
const Calibration = () => {
  const { locale, formatMessage } = useIntl();
  const handle = useFullScreenHandle();
  const openVideo = useSelector((state) => state.calibration.openVideo);
  const dispatch = useDispatch();
  const onEnd = () => dispatch(onEndAction());
  const onload = () => dispatch(load());
  const ref = useRef<ReactPlayer>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openPlayer, setOpenPlayer] = React.useState(false);
  const [playing, setPlaying] = React.useState(false);
  const [videoWatched, setVideoWatched] = React.useState(false);

  const exitVideo = () => {
    setVideoWatched(true);
    window.localStorage.setItem("_sal-Calibra", "done");
    if (handle.active) {
      handle.exit();
    }
    onEnd && onEnd();
    return onOpen();
  }; //sources for different locales
  const isDev = useIsDev();
  const videoSrc = isDev
    ? {
        ar: "https://vimeo.com/710291938",
        en: "https://vimeo.com/710291938",
      }
    : {
        ar: "https://vimeo.com/716100201",
        en: "https://vimeo.com/716100256",
      };
  const debouncedSeek = useMemo(
    () =>
      debounce((time: number) => {
        if (!times.includes(getReadableTime(time))) {
          if (iOS()) {
            setPlaying(false);
          }
          alert(
            formatMessage({
              id: "seeking",
            })
          );
          ref.current?.seekTo(1);
        }
      }, 1000),
    [formatMessage]
  );
  useEffect(() => {
    return () => {
      debouncedSeek.cancel();
    };
  }, [debouncedSeek]);

  // make sure the video is playing
  useEffect(() => {
    if (openPlayer && !playing) {
      setPlaying(true);
    }
  }, [openPlayer, playing]);
  return (
    <>
      <FullScreen handle={handle}>
        <div
          style={{
            display: openPlayer && openVideo ? "block" : "none",
            pointerEvents: "none",
          }}
        >
          <ReactPlayer
            onSeek={debouncedSeek}
            ref={ref}
            url={videoSrc[locale]}
            playing={playing}
            controls={false}
            onEnded={() => exitVideo()}
            width={"100vw"}
            height={"100vh"}
            className={classes.video}
            style={{
              zIndex: 2000,
            }}
          />
        </div>
      </FullScreen>
      {/*<video*/}
      {/*  preload="none"*/}
      {/*  onContextMenu={(e) => e.preventDefault()}*/}
      {/*  controlsList="nodownload"*/}
      {/*  key={locale}*/}
      {/*  // onPlay={HLS.isSupported() && getFullscreenPermission }*/}
      {/*  ref={videoRef}*/}
      {/*  controls*/}
      {/*  className="video-js vjs-big-play-centered"*/}
      {/*  // playsInline*/}
      {/*  // muted={isMuted}*/}
      {/*>*/}
      {/*  /!* <source src={`${MEDIA_URL}${videoSrc[locale as keyof typeof videoSrc]}`} /> *!/*/}
      {/*</video>*/}
      {openVideo ? (
        <Modal
          isCentered
          size={"xs"}
          isOpen={openVideo}
          onClose={() => {
            if (videoWatched) onEnd && onEnd();
            else {
              onload();
            }
          }}
          closeOnOverlayClick={false}
          closeOnEsc={false}
        >
          <ModalOverlay />
          <ModalContent
            px={"10px"}
            maxWidth={"550px"}
            width={"100%"}
            borderRadius={"30px"}
          >
            <ModalCloseButton />
            <ModalBody>
              <Text py={"15px"} textStyle={"h5"}>
                {formatMessage({ id: "calibration.modaltext" })}
              </Text>
              <Button
                onClick={() => {
                  setOpenPlayer(true);
                  setPlaying(true);
                  handle.enter().catch(() => null);
                }}
              >
                {formatMessage({
                  id: "calibration.start",
                })}
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : null}
      <Modal isCentered size={"xs"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          px={"10px"}
          maxWidth={"550px"}
          width={"100%"}
          borderRadius={"30px"}
        >
          <ModalCloseButton />
          <ModalBody>
            <Text py={"15px"} textStyle={"h5"}>
              <FormattedMessage
                id="calibration.done"
                defaultMessage={"تمت المعايرة بنجاح"}
              />
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Calibration;
