import React, { useState, useEffect, useCallback } from "react";
import BaseView from "../BaseView";
import MaleHead from "src/assets/images/man-head.png";
import MaleAbs from "src/assets/images/man-abbs.png";
import MaleLegs from "src/assets/images/man-limbs.png";
import FemaleHead from "src/assets/images/woman-head.png";
import FemaleAbs from "src/assets/images/woman-abbs.png";
import FemaleLegs from "src/assets/images/woman-limbs.png";
import { useDispatch, useSelector } from "src/store";
import { calibrationFunc as calibrationFuncAction } from "src/store/calibration";
import { HStack, Box, useDisclosure, Button, Text } from "@chakra-ui/react";
import { get } from "src/api/index";
import { Disease } from "src/types";
import useLocale from "src/providers/useLocale";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import CalibrationModal from "src/views/Affirmation/Calibration/CalibrationModal";
import { useHistory, useLocation, Link } from "react-router-dom";
import { INTENTION_ROUTE, SUBLIMINAL_ROUTE } from "src/routes";
import Calibration from "src/views/Affirmation/Calibration/Calibration";
import DiseaseModal from "./components/DiseaseModal";

//mapping for images
const map = {
  male: {
    head: MaleHead,
    abdomen: MaleAbs,
    limps: MaleLegs,
  },
  female: {
    head: FemaleHead,
    abdomen: FemaleAbs,
    limps: FemaleLegs,
  },
};
const Detail = () => {
  const dispatch = useDispatch();
  const calibrationFunc = () => dispatch(calibrationFuncAction());
  //current locale
  const [locale] = useLocale();

  //current user
  const user = useSelector((state) => state.auth.user);

  //history
  const history = useHistory();

  //method for obtaining current organd and gender
  const getSlugs = () => {
    const gender = window.location.pathname.split("/")[4];
    const section = window.location.pathname.split("/")[5];
    const organ = window.location.pathname.split("/")[6];
    return {
      gender,
      section,
      organ,
    };
  };

  //Tracking diseases
  const [list, setList] = useState<Disease[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  //count of organs for current section
  const [organsCount, setOrgansCount] = useState<any>({});

  //function to fetch organs count
  const countOrgans = useCallback(async () => {
    const { gender, section } = getSlugs();
    const response = await get(`/healing/${gender}/${section}`);
    if (response) setOrgansCount(response);
  }, []);

  //current pathname
  const { pathname } = useLocation();

  //function to correct pathname
  const correctPath = () => {
    return pathname
      .split("/")
      .filter((_, idx, arr) => idx !== arr.length - 1)
      .join("/");
  };

  //function to return the map for section parts
  const getImageMap = () => {
    if (!organsCount) return;
    const { gender, section } = getSlugs();
    //Depending on the section we return the map for it's corresponding parts
    switch (section) {
      case "head":
        return (
          <>
            <Box
              as={Link}
              to={!organsCount.forehead ? "#" : `${correctPath()}/forehead`}
              position="absolute"
              width="60%"
              height={gender === "male" ? "85px" : "70px"}
              top="0"
              left={locale === "en" ? "50%" : gender === "male" ? "46%" : "55%"}
              transform={
                gender === "male"
                  ? "translate(-46%, 55px)"
                  : "translate(-55%, 90px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.eyes ? "#" : `${correctPath()}/eyes`}
              position="absolute"
              width={gender === "male" ? "38%" : "36%"}
              height="40px"
              top="0"
              left={locale === "en" ? "50%" : gender === "male" ? "47%" : "54%"}
              transform={
                gender === "male"
                  ? "translate(-46%, 148px)"
                  : "translate(-56%, 166px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.nose ? "#" : `${correctPath()}/nose`}
              position="absolute"
              width={gender === "male" ? "15%" : "15%"}
              height="45px"
              top="0"
              left={locale === "en" ? "50%" : gender === "male" ? "47%" : "53%"}
              transform={
                gender === "male"
                  ? "translate(-40%, 190px)"
                  : "translate(-60%, 208px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.mouth ? "#" : `${correctPath()}/mouth`}
              position="absolute"
              width={gender === "male" ? "25%" : "25%"}
              height={gender === "male" ? "40px" : "35px"}
              top="0"
              left={locale === "en" ? "50%" : gender === "male" ? "47%" : "53%"}
              transform={
                gender === "male"
                  ? "translate(-45%, 240px)"
                  : "translate(-55%, 255px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.cheeks ? "#" : `${correctPath()}/cheeks`}
              position="absolute"
              width="10%"
              height={gender === "male" ? "45px" : "40px"}
              top="0"
              left="0"
              transform={
                gender === "male"
                  ? locale === "en"
                    ? "translate(115px , 190px)"
                    : "translate(107px , 190px)"
                  : locale === "en"
                  ? "translate(105px , 212px)"
                  : "translate(118px , 212px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.cheeks ? "#" : `${correctPath()}/cheeks`}
              position="absolute"
              width={gender === "male" ? "10%" : "9%"}
              height={gender === "male" ? "45px" : "40px"}
              top="0"
              right="0"
              transform={
                gender === "male"
                  ? locale === "en"
                    ? "translate(-105px, 190px)"
                    : "translate(-117px, 190px)"
                  : locale === "en"
                  ? "translate(-120px, 212px)"
                  : "translate(-110px, 212px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.ears ? "#" : `${correctPath()}/ears`}
              position="absolute"
              width="8%"
              height="65px"
              top="0"
              left="0"
              transform={
                gender === "male"
                  ? locale === "en"
                    ? "translate(85px, 160px)"
                    : "translate(73px, 160px)"
                  : locale === "en"
                  ? "translate(75px, 170px)"
                  : "translate(88px, 170px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.ears ? "#" : `${correctPath()}/ears`}
              position="absolute"
              width="8%"
              height="65px"
              top="0"
              right="0"
              transform={
                gender === "male"
                  ? locale === "en"
                    ? "translate(-73px, 160px)"
                    : "translate(-83px, 160px)"
                  : locale === "en"
                  ? "translate(-90px, 172px)"
                  : "translate(-75px, 172px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.neck ? "#" : `${correctPath()}/neck`}
              position="absolute"
              width={gender === "male" ? "65%" : "56%"}
              height="40px"
              top="0"
              left={locale === "en" ? "50%" : gender === "male" ? "46%" : "60%"}
              transform={
                gender === "male"
                  ? "translate(-45%, 305px)"
                  : "translate(-60%, 315px)"
              }
            />
          </>
        );
      case "abdomen":
        return (
          <>
            <Box
              as={Link}
              to={!organsCount.chest ? "#" : `${correctPath()}/chest`}
              position="absolute"
              width="100%"
              height={gender === "male" ? "120px" : "130px"}
              top="0"
              left="50%"
              transform={
                gender === "male"
                  ? "translate(-50%, 130px)"
                  : "translate(-50%, 170px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.belly ? "#" : `${correctPath()}/belly`}
              position="absolute"
              width="80%"
              height="130px"
              top="0"
              left="50%"
              transform={
                gender === "male"
                  ? "translate(-50%, 255px)"
                  : "translate(-50%, 305px)"
              }
            />
          </>
        );
      case "limps":
        return (
          <>
            <Box
              as={Link}
              to={!organsCount.arms ? "#" : `${correctPath()}/arms`}
              position="absolute"
              width={gender === "male" ? "16%" : "14%"}
              height="250px"
              top="0"
              left="0"
              transform={
                gender === "male"
                  ? locale === "en"
                    ? "translate(60px, 150px) rotate(8deg)"
                    : "translate(45px, 150px) rotate(-8deg)"
                  : locale === "en"
                  ? "translate(75px, 195px) rotate(8deg)"
                  : "translate(55px, 195px) rotate(-8deg)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.arms ? "#" : `${correctPath()}/arms`}
              position="absolute"
              width={gender === "male" ? "16%" : "14%"}
              height="250px"
              top="0"
              right="0"
              transform={
                gender === "male"
                  ? locale === "en"
                    ? "translate(-45px, 150px) rotate(-8deg)"
                    : "translate(-60px, 150px) rotate(8deg)"
                  : locale === "en"
                  ? "translate(-57px, 195px) rotate(-8deg)"
                  : "translate(-75px, 195px) rotate(8deg)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.hands ? "#" : `${correctPath()}/hands`}
              position="absolute"
              width={gender === "male" ? "13%" : "11%"}
              height={gender === "male" ? "65px" : "75px"}
              top="0"
              left="0"
              transform={
                gender === "male"
                  ? locale === "en"
                    ? "translate(60px, 410px)"
                    : "translate(45px, 410px)"
                  : locale === "en"
                  ? "translate(60px, 455px)"
                  : "translate(45px, 455px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.hands ? "#" : `${correctPath()}/hands`}
              position="absolute"
              width={gender === "male" ? "13%" : "11%"}
              height={gender === "male" ? "65px" : "75px"}
              top="0"
              right="0"
              transform={
                gender === "male"
                  ? locale === "en"
                    ? "translate(-45px, 410px)"
                    : "translate(-60px, 410px)"
                  : locale === "en"
                  ? "translate(-44px, 455px)"
                  : "translate(-62px, 455px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.legs ? "#" : `${correctPath()}/upperLegs`}
              position="absolute"
              width={gender === "male" ? "42%" : "45%"}
              height={gender === "male" ? "195px" : "220px"}
              top="0"
              left={locale === "en" ? "50%" : "46%"}
              transform={
                gender === "male"
                  ? "translate(-45%, 360px)"
                  : "translate(-45%, 410px)"
              }
            />
            <Box
              as={Link}
              to={!organsCount.feet ? "#" : `${correctPath()}/lowerLegs`}
              position="absolute"
              width="42%"
              height="210px"
              top="0"
              left={locale === "en" ? "50%" : "46%"}
              transform={
                gender === "male"
                  ? "translate(-45%, 565px)"
                  : "translate(-45%, 635px)"
              }
            />
          </>
        );
    }
  };
  //calibration modal
  const [open, setOpen] = useState(false);

  //current opened modal
  const [current, setCurrent] = useState<Disease>();

  //method for fetching list
  const getList = useCallback(async () => {
    const { gender, organ, section } = getSlugs();
    // Functions to map organs
    const getCorrectOrgan = (organ) => {
      switch (organ) {
        case "upperLegs":
          return "legs";
        case "lowerLegs":
          return "feet";
        default:
          return organ;
      }
    };
    const response = await get(
      `/healing/${gender}/${section}/${getCorrectOrgan(organ)}`,
      locale === "ar" || locale === "en" ? locale : "ar",
      user?.accessToken
    );
    setLoading(false);
    if (response && Array.isArray(response)) setList(response);
    else setError("Failed to fetch data!");
  }, [locale, user?.accessToken]);

  //fetch list once component mounts
  useEffect(() => {
    getList();
    countOrgans();
  }, [countOrgans, getList, locale, pathname]);

  //visibility control for modal
  const disclosureProps = useDisclosure();

  //method to open modal
  const openModal = (obj: Disease) => {
    setCurrent(obj);
    disclosureProps.onOpen();
  };

  //method to go for intention sending
  const goToIntention = () => {
    //User have to be calibrated before redirection
    if (window.localStorage.getItem("_sal-Calibra") !== "done") {
      setOpen(true);
    } else {
      history.push(`${INTENTION_ROUTE}?sym=${current?.id}`);
    }
  };

  const goToSubliminal = () => {
    if (window.localStorage.getItem("_sal-Calibra") !== "done") {
      setOpen(true);
    } else {
      history.push(`${SUBLIMINAL_ROUTE}?sym=${current?.id}&idx=2`);
    }
  };

  return (
    <BaseView>
      <HStack
        display="flex"
        flexDir={{ base: "column", xl: "row-reverse" }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box
          position="relative"
          width="350px"
          alignSelf={"flex-start"}
          // transform={{ xl: "translateX(200px)" }}
        >
          <img
            style={{ width: "100%", height: "100%" }}
            alt={"detail"}
            src={
              map[getSlugs().gender as keyof typeof map][
                getSlugs().section as keyof typeof map.male &
                  keyof typeof map.female
              ]
            }
          />
          {getImageMap()}
        </Box>
        <Box
          display="flex"
          flexDir="column"
          alignItems="center"
          paddingTop={"30px"}
          width={{ base: "70%", md: "70%", xl: "350px", "2xl": "450px" }}
        >
          {loading || error ? (
            <Box padding={"20px"}>
              {loading && "Loading..."}
              {error && error}
            </Box>
          ) : (
            list.map((item) => (
              <Box
                key={item.id}
                padding="15px"
                background="linear-gradient(to right, #1a75cf, #003366)" /* Gradient background */
                borderRadius="10px"
                boxSizing="border-box"
                boxShadow="0px 3px 5px rgba(192, 192, 192, 0.6)" /* Subtle shadow */
                transition="all 250ms ease-out" /* Smooth transition */
                border="none" /* Remove any borders */
                _hover={{
                  borderColor: "#2895ff" /* Border color on hover */,
                  transform:
                    "scale(1.03)" /* Slight scaling for hover feedback */,
                  boxShadow:
                    "0px 4px 10px rgba(0, 0, 0, 0.2)" /* Enhanced shadow on hover */,
                }}
                cursor="pointer"
                margin="10px 0"
                onClick={() => openModal(item)} /* Action handler */
                textColor="white"
                width="100%"
              >
                <Text
                  fontWeight={"bold"}
                  fontFamily={"Calibri (Body)"}
                  fontSize={"18"}
                >
                  {item.name}
                </Text>
              </Box>
            ))
          )}
        </Box>
        <DiseaseModal
          isOpen={disclosureProps.isOpen}
          onClose={() => {
            disclosureProps.onClose();
            setCurrent(undefined);
          }}
          disease={current}
          onSendIntention={goToIntention}
          onSubliminal={goToSubliminal}
        />
        <Calibration />
        <CalibrationModal
          children={
            <Button
              onClick={() => {
                calibrationFunc && calibrationFunc();
                setOpen(false);
              }}
              color={"white"}
              width={
                localStorage.getItem("locale") === "ar" ? "168px" : "176px"
              }
              height={"40px"}
              background={"#2895ff"}
              fontFamily={"tajwal"}
              fontSize={"16px"}
              fontWeight={"normal"}
              mt={"20px"}
              rightIcon={
                <AiOutlinePlayCircle
                  fontSize={"24px"}
                  style={
                    localStorage.getItem("locale") === "ar"
                      ? { marginRight: "1rem" }
                      : { marginLeft: "1px" }
                  }
                />
              }
            >
              <FormattedMessage
                id="calibration.start"
                defaultMessage={"بدء المعايرة"}
              />
            </Button>
          }
          open={open}
          modalClose={() => setOpen(false)}
        />
      </HStack>
    </BaseView>
  );
};

export default Detail;
