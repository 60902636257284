import React, { useState } from "react";
import {
  Box,
  Flex,
  FormLabel,
  Button,
  Stack,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  IconButton,
  Text,
  Center,
  Avatar,
} from "@chakra-ui/react";
import { Redirect } from "react-router-dom";
import DarkHeader from "src/layout/DarkHeader";
import SubscriptionDetails from "./SubscriptionDetails";
import { useSelector } from "src/store";
import PersonalInfo from "src/components/PersonalInfo/PersonalInfo";
import { EyeOff, Eye, Lock, Camera } from "lucide-react";
import { useIntl } from "react-intl";
import ProfileImage from "src/components/ProfileImage/ProfileImage";
import { BASE_MEDIA } from "src/configs/config";
import Security from "src/components/Security/Security";

const Settings: React.FC = () => {
  const { formatMessage } = useIntl();
  const user = useSelector((state) => state.auth.user);
  const [isEditing, setIsEditing] = useState(true);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPasswordData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    console.log(passwordData);
  };

  if (user?.subscriptionStatus !== "approved") return <Redirect to="/" />;

  return (
    <Box>
      <DarkHeader />
      <Box
        d={"flex"}
        flexDir={"column"}
        gap={5}
        pt="20px"
        pb="120px"
        mx={"auto"}
        maxW="800px"
        px={2}
      >
        <ProfileImage />
        <PersonalInfo />
        <Security />
        <SubscriptionDetails />
      </Box>
    </Box>
  );
};

export default Settings;
