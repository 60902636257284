import React, {
  useState,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { LocaleProvider } from "./providers/LocaleProvider";
import { BrowserRouter } from "react-router-dom";
import theme from "./theme";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import "./richTextStyles.css";
import MediaBox from "src/providers/MediaBox";
import { Provider as ReduxProvider } from "react-redux";
import reduxStore from "src/store";
import CookieChecker from "./components/CookieChecker/CookieChecker";
import CrashWrapper from "./views/Crash/Crash";
import FiltersProvider from "./views/Prepration/SalPlan/containers/FiltersContext";
import { Font } from "@react-pdf/renderer";
import { MEDIA_URL } from "./api";

const queryClient = new QueryClient();

const initialContext: {
  background: {
    get: string;
    set: Dispatch<SetStateAction<string>>;
  };
} = {
  background: {
    get: "#fff",
    set: () => {},
  },
};
export const AppContext = React.createContext(initialContext);
export const useAppContext = () => useContext(AppContext);
const Providers = ({ children }: { children: React.ReactNode }) => {
  const [background, setBackground] = useState("#fff");
  const [accepted, setAccepted] = useState(
    window.localStorage.getItem("__sal_ca") === "1"
  );
  const [analyticsAccepted, setAnalyticsAccepted] = useState(
    window.localStorage.getItem("__sal_analytics_consent") !== null
  );
  const store = {
    background: {
      get: background,
      set: setBackground,
    },
  };

  useEffect(() => {
    // this font related to focus poster
    Font.register({
      family: "Droid",
      src: `${MEDIA_URL}/fonts/1625044265876-160603289.ttf`,
    });
  }, []);

  return (
    <CrashWrapper>
      <ReduxProvider store={reduxStore}>
        <AppContext.Provider value={store}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <ChakraProvider theme={theme}>
                <LocaleProvider>
                  <FiltersProvider>
                    {(!navigator.cookieEnabled ||
                      window.localStorage.getItem("__sal_ca") !== "1") &&
                      !accepted && (
                        <CookieChecker accept={() => setAccepted(true)} />
                      )}
                    {accepted &&
                      window.localStorage.getItem("__sal_analytics_consent") ===
                        null && (
                        <CookieChecker
                          accept={() => setAnalyticsAccepted(true)}
                          analyticsOnly
                        />
                      )}
                    <MediaBox>{children}</MediaBox>
                  </FiltersProvider>
                </LocaleProvider>
              </ChakraProvider>
            </BrowserRouter>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </AppContext.Provider>
      </ReduxProvider>
    </CrashWrapper>
  );
};

export default Providers;
