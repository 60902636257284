import React, { useState } from "react";
import {
  Box,
  FormLabel,
  Button,
  Stack,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  IconButton,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { EyeOff, Eye, Lock } from "lucide-react";
import { useIntl } from "react-intl";
import { useSelector } from "src/store";
import { put } from "src/api/index";

const isValidPassword = (password: string): boolean => {
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
  return passwordRegex.test(password);
};

type ApiError = {
  message: string | string[];
  statusCode?: number;
};

const Security: React.FC = () => {
  const { formatMessage } = useIntl();
  const user = useSelector((state) => state.auth.user);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [errors, setErrors] = useState<string[]>([]);
  const toast = useToast();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    switch (name) {
      case "newPassword":
        setPasswordError(
          !value || isValidPassword(value)
            ? ""
            : formatMessage({ id: "passwordRequirements" })
        );
        setConfirmPasswordError(
          value === passwordData.confirmPassword
            ? ""
            : formatMessage({ id: "passwordMismatch" })
        );
        break;
      case "confirmPassword":
        setConfirmPasswordError(
          value === passwordData.newPassword
            ? ""
            : formatMessage({ id: "passwordMismatch" })
        );
        break;
    }

    setPasswordData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      setErrors([]);

      if (!passwordData.oldPassword) {
        setErrors([formatMessage({ id: "currentPasswordRequired" })]);
        return;
      }

      if (
        !passwordData.newPassword ||
        !isValidPassword(passwordData.newPassword)
      ) {
        setErrors([formatMessage({ id: "passwordRequirements" })]);
        return;
      }

      if (passwordData.newPassword !== passwordData.confirmPassword) {
        setErrors([formatMessage({ id: "passwordMismatch" })]);
        return;
      }

      const response = await put(
        "/user/me/password",
        {
          oldPassword: passwordData.oldPassword,
          newPassword: passwordData.newPassword,
        },
        undefined,
        user?.accessToken
      );

      if (response?.id) {
        toast({
          title: formatMessage({ id: "saved" }),
          status: "success",
          duration: 3000,
        });
        setPasswordData({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      } else {
        const error = response as ApiError;
        if (error.message) {
          const messages = Array.isArray(error.message)
            ? error.message
            : [error.message];
          setErrors(messages);
        } else {
          setErrors([formatMessage({ id: "wentWrong" })]);
        }
      }
    } catch (error: any) {
      const messages = error?.response?.data?.message;
      if (messages) {
        setErrors(Array.isArray(messages) ? messages : [messages]);
      } else {
        setErrors([formatMessage({ id: "wentWrong" })]);
      }
      console.error("Error updating password:", error);
    }
  };

  return (
    <Box
      w="full"
      p={6}
      borderWidth="1px"
      borderRadius="lg"
      sx={{ fontFamily: "Calibri, sans-serif" }}
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.2)"
    >
      <Box mb={4}>
        <Text fontSize="2xl" fontWeight="semibold" color="#162d6a">
          {formatMessage({ id: "security.title" })}
        </Text>
        <Text color="gray.500" fontSize="md">
          {formatMessage({ id: "security.subtitle" })}
        </Text>
      </Box>

      <Stack mb={6}>
        <FormLabel mb={0}>{formatMessage({ id: "currentPassword" })}</FormLabel>
        <InputGroup>
          <InputLeftElement children={<Lock size={16} />} />
          <Input
            name="oldPassword"
            value={passwordData.oldPassword}
            onChange={handleChange}
            type={showPassword ? "text" : "password"}
            placeholder={formatMessage({ id: "enterCurrentPassword" })}
          />
          <InputRightElement>
            <IconButton
              size="sm"
              variant="ghost"
              onClick={() => setShowPassword(!showPassword)}
              icon={showPassword ? <EyeOff size={16} /> : <Eye size={16} />}
              aria-label={showPassword ? "Hide password" : "Show password"}
            />
          </InputRightElement>
        </InputGroup>

        <FormLabel mb={0}>{formatMessage({ id: "newPassword" })}</FormLabel>
        <InputGroup>
          <InputLeftElement children={<Lock size={16} />} />
          <Input
            name="newPassword"
            value={passwordData.newPassword}
            onChange={handleChange}
            type={showPassword ? "text" : "password"}
            placeholder={formatMessage({ id: "enterNewPassword" })}
            isInvalid={!!passwordError}
          />
          <InputRightElement>
            <IconButton
              size="sm"
              variant="ghost"
              onClick={() => setShowPassword(!showPassword)}
              icon={showPassword ? <EyeOff size={16} /> : <Eye size={16} />}
              aria-label={showPassword ? "Hide password" : "Show password"}
            />
          </InputRightElement>
        </InputGroup>
        {passwordError && (
          <Text color="red.500" fontSize="sm" mt={1}>
            {passwordError}
          </Text>
        )}

        <FormLabel mb={0}>{formatMessage({ id: "confirmPassword" })}</FormLabel>
        <InputGroup>
          <InputLeftElement children={<Lock size={16} />} />
          <Input
            name="confirmPassword"
            value={passwordData.confirmPassword}
            onChange={handleChange}
            type={showPassword ? "text" : "password"}
            placeholder={formatMessage({ id: "confirmNewPassword" })}
            isInvalid={!!confirmPasswordError}
          />
          <InputRightElement>
            <IconButton
              size="sm"
              variant="ghost"
              onClick={() => setShowPassword(!showPassword)}
              icon={showPassword ? <EyeOff size={16} /> : <Eye size={16} />}
              aria-label={showPassword ? "Hide password" : "Show password"}
            />
          </InputRightElement>
        </InputGroup>
        {confirmPasswordError && (
          <Text color="red.500" fontSize="sm" mt={1}>
            {confirmPasswordError}
          </Text>
        )}
      </Stack>

      {errors.length > 0 && (
        <Box
          w="full"
          p={4}
          mb={4}
          bg="red.50"
          borderRadius="md"
          borderLeft="4px"
          borderColor="red.500"
        >
          <VStack align="stretch" spacing={1}>
            {errors.map((error, index) => (
              <Text
                key={index}
                color="red.600"
                fontSize="sm"
                display="flex"
                alignItems="center"
              >
                {error}
              </Text>
            ))}
          </VStack>
        </Box>
      )}

      <Button
        w="full"
        color="white"
        bg="#1f3f93"
        _hover={{ bg: "#aaaabc" }}
        onClick={handleSubmit}
        isDisabled={
          !passwordData.oldPassword ||
          !passwordData.newPassword ||
          !passwordData.confirmPassword ||
          !!passwordError ||
          !!confirmPasswordError
        }
      >
        {formatMessage({ id: "updatePassword" })}
      </Button>
    </Box>
  );
};

export default Security;
